
<script setup>
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'
import {Link} from '@inertiajs/vue3'
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import SecondaryButton from "../../Components/SecondaryButton.vue";
import Dropdown from "../../Components/Dropdown.vue";


const navigation = [
    { name: 'Home', href: route('home') },
    { name: 'Pricing', href: route('home') + '#pricing' },
    { name: 'File Importer', href: route('import') },
    { name: 'Support', href: route('home.contact') },
]

</script>

<script>

export default {
    props: {
        plans: {
            type: Array,
            default: []
        },
        frequencies: {
            type: Array,
            default: []
        },
        subscribed: {
            type: Boolean,
            default: false
        },
        hasExpiredTrial: {
            type: Boolean,
            default: false
        },
        onTrial: {
            type: Boolean,
            default: false
        },
        trialEndsAt: {
            type: String,
            default: ''
        },
        activeTier: {
            type: Object,
            default: {}
        },
        canLogin: Boolean,
        canRegister: Boolean,
    },
    methods: {
        handleScroll(){
            // when the user scrolls, check the pageYOffset
            if(window.pageYOffset>0){
                // user is scrolled
                if(this.view.atTopOfPage) this.view.atTopOfPage = false
            }else{
                // user is at top of page
                if(!this.view.atTopOfPage) this.view.atTopOfPage = true
            }
        },
        changeTheme(theme) {
            if (theme === 'light') {
                localStorage.theme = 'light';
                this.theme = 'Light';
            } else if (theme === 'dark') {
                localStorage.theme = 'dark';
                this.theme = 'Dark';
            } else {
                localStorage.theme = 'system';
                this.theme = 'System';
            }

            // On page load or when changing themes
            if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
                // if dark mode or no preference set, set dark mode
                document.documentElement.classList.add('dark');
            } else if (localStorage.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // if system is set and system is dark, set dark mode
                document.documentElement.classList.add('dark');
            } else {
                document.documentElement.classList.remove('dark');
            }
        },
    },
    data () {
        return {
            view: {
                atTopOfPage: true
            },
            theme: 'Dark Mode',
        }
    },
    beforeMount () {
        window.addEventListener('scroll', this.handleScroll);
    },
    mounted() {
        if (localStorage.theme === 'dark') {
            this.theme = 'Dark';
        } else if (localStorage.theme === 'light') {
            this.theme = 'Light';
        } else if (localStorage.theme === 'system') {
            this.theme = 'System';
        } else {
            this.theme = 'Dark Mode';
        }

        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", function (e) {
                const colorScheme = e.matches ? "dark" : "light";

                if (colorScheme === "dark") {
                    if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
                        // if dark mode or no preference set, set dark mode
                        document.documentElement.classList.add('dark');
                    } else if (localStorage.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                        // if system is set and system is dark, set dark mode
                        document.documentElement.classList.add('dark');
                    } else {
                        document.documentElement.classList.remove('dark');
                    }
                } else {
                    if (localStorage.theme === 'dark' || !('theme' in localStorage)) {
                        // if dark mode or no preference set, set dark mode
                        document.documentElement.classList.add('dark');
                    } else if (localStorage.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                        // if system is set and system is dark, set dark mode
                        document.documentElement.classList.add('dark');
                    } else {
                        document.documentElement.classList.remove('dark');
                    }
                }
            });
    }
}

</script>

<template>
    <header class="absolute inset-x-0 top-0 z-50">
        <Disclosure as="nav" :class="{
            'bg-zinc-950/70 backdrop-blur-lg border-b': !view.atTopOfPage,
            'bg-transparent backdrop-blur-none border-b-0': view.atTopOfPage
        }"  class=" backdrop-blur-lg border-sidebar-dark fixed w-full duration-150" v-slot="{ open }">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div class="flex h-16 justify-between">
                    <div class="flex">
                        <div class="-ml-2 mr-2 flex items-center md:hidden">
                            <!-- Mobile menu button -->
                            <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-zinc-400 hover:text-white focus:outline-none focus:ring-none duration-150">
                                <span class="sr-only">Open main menu</span>
                                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                            </DisclosureButton>
                        </div>
                      <Link :href="route('home')" class="flex flex-shrink-0 items-center px-4 py-6 group">
                        <img class="h-5 w-auto group-hover:scale-[115%] peer duration-[400ms] group-hover:-rotate-180" src="/assets/icon.svg" alt="Icon" />
                        <img class="h-6 inline ml-3 duration-[400ms] w-auto" src="/assets/logo_text.svg" alt="Synci" />
                      </Link>
                        <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                            <Link v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-zinc-900 text-white' : 'text-zinc-100 hover:bg-zinc-100/[7.5%]', 'rounded-md px-3 py-2 duration-100 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</Link>
                        </div>
                    </div>
                    <div class="flex items-center md:flex md:items-center md:space-x-4">
                        <div v-if="!$page.props.auth.user" class="flex-shrink-0">
                            <Link :href="route('register')" class="text-zinc-100 hover:bg-zinc-100/[7.5%] rounded-md px-3 py-2 text-sm font-medium duration-100">Sign up</Link>
                        </div>
                        <div class="flex-shrink-0">
                            <Link :href="route('login')" class="text-zinc-100 hover:bg-zinc-100/[7.5%] rounded-md px-3 py-2 text-sm font-medium duration-100"><span v-if="!$page.props.auth.user">Log in <span aria-hidden="true">&rarr;</span></span><span v-else>Dashboard <span aria-hidden="true">&rarr;</span></span></Link>
                        </div>
                    </div>
                </div>
            </div>

            <DisclosurePanel class="md:hidden duration:150" :class="view.atTopOfPage && open ? 'bg-zinc-950/70 backdrop-blur-lg' : ''">
                <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    <Link v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-zinc-900 text-white' : 'text-zinc-100 hover:bg-zinc-100/[7.5%]', 'block border border-transparent rounded-md px-3 py-2 text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</Link>
                </div>
            </DisclosurePanel>
        </Disclosure>
    </header>
</template>
